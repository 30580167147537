<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <button
      id="print-btn"
      class="btn btn-success"
      @click="handlePrint"
    >
      Print
    </button>
    <!--    <button-->
    <!--      v-b-modal.order-details-->
    <!--      class="btn btn-primary mx-2"-->
    <!--    >-->
    <!--      Order Details-->
    <!--    </button>-->
    <router-link
      :to="`/orders/${order.id}`"
      class="btn btn-primary mx-2"
    >
      Go To Order Details
    </router-link>
    <!--    <order-details-->
    <!--      v-if="Object.keys(order).length > 0"-->
    <!--      :order="order.id"-->
    <!--    />-->
    <div
      v-if="Object.keys(order).length > 0"
      id="invoice-POS"
    >

      <center id="top">
        <div class="logo">
          <img
            :src="require('@/assets/images/logo/logo.png')"
            width="60"
            height="60"
          >
        </div>
        <div class="info">
          <h2>Alrabiea</h2>
        </div><!--End Info-->
      </center><!--End InvoiceTop-->
      <div id="mid-1">
        <div class="info">
          <!-- <h2>Contact Info</h2> -->
          <p>
            <span>
              <strong>رقم الطلب</strong>
            </span>
            <br>
            <span>
              <strong>Order ID</strong> : {{ order.id }}
            </span>
            <br>
            <span>
              <strong>رقم الفاتورة</strong>
            </span>
            <br>
            <span>
              <strong>Invoice Number</strong> : #{{ order.invoice_id }}
            </span>
            <br>
            <span v-if="order.items_add_date">
              <strong>تاريخ الفاتورة</strong>
            </span>
            <br>
            <span v-if="order.items_add_date">
              <strong>Invoice Date</strong> : {{ order.items_add_date }}
            </span>
            <br>
            <span v-if="order.delivery_time">
              <strong>وقت التسليم</strong>
            </span>
            <br>
            <span v-if="order.delivery_time">
              <strong>Delivery Time</strong> : {{ order.delivery_time.day.week_day }} ({{ order.delivery_time.start_at }} - {{ order.delivery_time.end_at }})
            </span>
            <br>
          </p>
        </div>
      </div><!--End Invoice Mid-->
      <div id="mid">
        <div class="info">
          <h2>
            <span><strong>بيانات التواصل</strong></span>
            <br>
            <span><strong>Contact Info</strong></span>
          </h2>
          <p>
            <span>
              <strong>الاسم</strong>
            </span>
            <br>
            <span>
              <strong>Name</strong> : {{ order.user.full_name }}
            </span>
            <br>
            <span>
              <strong>البريد الإلكتروني</strong>
            </span>
            <br>
            <span>
              <strong>Email</strong> : {{ order.user.email }}
            </span>
            <br>
            <span>
              <strong>الهاتف</strong>
            </span>
            <br>
            <span>
              <strong>Phone</strong> : {{ order.user.phone }}
            </span>
            <br>
            <span v-if="fullAddress">
              <strong>العنوان</strong>
            </span>
            <br>
            <span v-if="fullAddress">
              <strong>Address</strong> : {{ fullAddress }}
            </span>
          </p>
        </div>
      </div><!--End Invoice Mid-->
      <div
        v-if="user.subscriptions_balance || user.wallet_amount"
        id="mid-1"
      >
        <div class="info">
          <!-- <h2>Info</h2> -->
          <p>
            <span v-if="user.subscriptions_balance">
              <strong>رصيد الاشتراكات</strong>
            </span>
            <br v-if="user.subscriptions_balance">
            <span v-if="user.subscriptions_balance">
              <strong>Subscriptions Balance</strong> : {{ user.subscriptions_balance }} KWD
            </span>
            <br v-if="user.subscriptions_balance">
            <span v-if="user.wallet_amount">
              <strong>رصيد المحفظة</strong>
            </span>
            <br v-if="user.wallet_amount">
            <span v-if="user.wallet_amount">
              <strong>Wallet Amount</strong> : {{ user.wallet_amount }} KWD
            </span>
            <br v-if="user.wallet_amount">
          </p>
        </div>
      </div>
      <div
        v-if="user.preferences"
        id="mid-2"
      >
        <p v-if="user.preferences.perfuming">
          <span>
            <strong>إضافة عطر</strong>
          </span>
          <br>
          <span>
            <strong>Add Perfume</strong>
          </span>
          <br>
        </p>
        <p v-if="user.preferences.ironing_type">
          <span>
            <strong>نوع الكي</strong>
          </span>
          <br>
          <span>
            <strong>Ironing Type</strong> : {{ user.preferences.ironing_type.name }}
          </span>
          <br>
        </p>
        <p v-if="user.preferences.starch_quantity">
          <span>
            <strong>كمية النشا</strong>
          </span>
          <br>
          <span>
            <strong>Starch Quantity</strong> : {{ user.preferences.starch_quantity.name }}
          </span>
          <br>
        </p>
        <p v-if="user.preferences.folding_and_hanging_type">
          <span>
            <strong>الطي والتعليق</strong>
          </span>
          <br>
          <span>
            <strong>Folding And Hanging Type</strong> : {{ user.preferences.folding_and_hanging_type.name }}
          </span>
          <br>
        </p>
      </div>
      <div
        v-if="user.preferences"
      >
        <div
          v-if="user.preferences.handling_call_me || (user.preferences.handling_call_another_number && user.preferences.handling_phone) || user.preferences.handling_use_door_bill"
          id="mid-2"
        >
          <div class="info">
            <h2>
              <span>ملاحظات لسواق الاستلام</span>
              <br>
              <span>Notes For Handling Driver</span>
              <br>
            </h2>
            <p v-if="user.preferences.handling_call_me">
              <span>
                <strong>اتصل بي</strong>
              </span>
              <br>
              <span>
                <strong>Call Me</strong>
              </span>
              <br>
            </p>
            <p v-if="user.preferences.handling_call_another_number && user.preferences.handling_phone">
              <span>
                <strong>اتصل بهذا الرقم</strong>
              </span>
              <br>
              <span>
                <strong>Call This Number</strong> : {{ user.preferences.handling_phone }}
              </span>
              <br>
            </p>
            <p v-if="user.preferences.handling_use_door_bill">
              <span>
                <strong>استخدم جرس المنزل</strong>
              </span>
              <br>
              <span>
                <strong>Use Door Bill</strong>
              </span>
              <br>
            </p>
          </div>
        </div>
        <div
          v-if="user.preferences.delivery_call_me || (user.preferences.delivery_call_another_number && user.preferences.delivery_phone) || user.preferences.delivery_use_door_bill"
          id="mid-2"
        >
          <div class="info">
            <h2>
              <span>ملاحظات لسواق التسليم</span>
              <br>
              <span>Notes For Delivery Driver</span>
              <br>
            </h2>
            <p v-if="user.preferences.delivery_call_me">
              <span>
                <strong>اتصل بي</strong>
              </span>
              <br>
              <span>
                <strong>Call Me</strong>
              </span>
              <br>
            </p>
            <p v-if="user.preferences.delivery_call_another_number && user.preferences.delivery_phone">
              <span>
                <strong>اتصل بهذا الرقم</strong>
              </span>
              <br>
              <span>
                <strong>Call This Number</strong> : {{ user.preferences.delivery_phone }}
              </span>
              <br>
            </p>
            <p v-if="user.preferences.delivery_use_door_bill">
              <span>
                <strong>استخدم جرس المنزل</strong>
              </span>
              <br>
              <span>
                <strong>Use Door Bill</strong>
              </span>
              <br>
            </p>
          </div>
        </div>
      </div>
      <!-- <div id="mid">
        <div class="info">
          <h2>Order Info</h2>
          <p>
            <span>   <strong>Notes</strong>   : {{ order.user_note }}</span> <br>
          </p>
        </div>
      </div> -->
      <!--End Invoice Mid-->
      <div id="bot">

        <div id="table">
          <table>
            <tr class="tabletitle">
              <td class="item">
                <h2>
                  <span>المنتج</span>
                  <br>
                  <span>Item</span>
                  <br>
                </h2>
              </td>
              <td class="Hours">
                <h2>
                  <span>العدد</span>
                  <br>
                  <span>Qty</span>
                  <br>
                </h2>
              </td>
              <td class="item">
                <h2>
                  <span>الخدمة</span>
                  <br>
                  <span>Service</span>
                  <br>
                </h2>
              </td>
              <td class="Rate">
                <h2>
                  <span>السعر</span>
                  <br>
                  <span>Price</span>
                  <br>
                </h2>
              </td>
            </tr>
            <tbody
              v-for="(item, index) in order.items"
              :key="index"
              class="service"
            >
              <tr>
                <td class="tableitem">
                  <span class="itemtext">
                    {{ item.name_ar }}
                  </span>
                  <br/>
                  <span class="itemtext">
  <!--                    {{ item.parent_name }} ({{ item.name }})-->
                    {{ item.name }}
                  </span>
                </td>
                <td class="tableitem text-center">
                  <span class="itemtext">
                    {{ item.quantity }}
                  </span>
                </td>
                <td class="tableitem text-center">
                  <span class="itemtext">
                    {{ item.service_type_ar }}
                  </span>
                  <br>
                  <span class="itemtext">
                    {{ item.service_type }}
                  </span>
                </td>
                <td class="tableitem text-center">
                  <span class="itemtext">
                    {{ item.item_price }}
                  </span>
                </td>
              </tr>
              <tr
                v-if="item.note"
              >
                <td
                  class="tableitem noteitem"
                  colspan="4"
                >
                  <span class="itemtext notetext">
                    <span>
                      ملحوظة (Note): {{ item.note }}
                    </span>
                  </span>
                  <br>
                </td>
              </tr>
            </tbody>
            <tr
              v-if="order.user_note"
            >
              <td
                class="tableitem"
                colspan="4"
              >
                <p class="itemtext">
                  ملحوظة عامة (General Note): {{ order.user_note }}
                </p>
              </td>
            </tr>
            <!--            <tr-->
            <!--              v-if="order.payment_method || (!order.payment_method && +order.remaining_total === 0 && order.paid)"-->
            <!--              class="tabletitle"-->
            <!--            >-->
            <!--              <td class="Rate">-->
            <!--                <h2>Payment Method</h2>-->
            <!--              </td>-->
            <!--              <td></td>-->
            <!--              <td-->
            <!--                class="payment"-->
            <!--                colspan="2"-->
            <!--              >-->
            <!--                <h2 v-if="order.payment_method">-->
            <!--                  {{ order.payment_method.name }}-->
            <!--                </h2>-->
            <!--                <h2 v-else>-->
            <!--                  Subscription-->
            <!--                </h2>-->
            <!--              </td>-->
            <!--            </tr>-->
<!--            <tr class="tabletitle">-->
<!--              <td class="Rate">-->
<!--                <h2>-->
<!--                  <span>عدد المنتجات</span>-->
<!--                  <br>-->
<!--                  <span>Items Number</span>-->
<!--                  <br>-->
<!--                </h2>-->
<!--              </td>-->
<!--              <td></td>-->
<!--              <td-->
<!--                class="payment"-->
<!--                colspan="2"-->
<!--              >-->
<!--                <h2>{{ totalItems }} {{ totalItems === 1 ? 'item' : 'items' }}</h2>-->
<!--              </td>-->
<!--            </tr>-->
            <tr
              class="tabletitle"
            >
              <td class="Rate">
                <h2>
                  <span>مجموع سعر المنتجات</span>
                  <br>
                  <span>Items Total</span>
                  <br>
                </h2>
              </td>
              <td>{{ totalItems }}</td>
              <td></td>
              <td
                class="payment"
                colspan="2"
              >
                <h2>{{ (order.total).toFixed(2) }}
                  KWD</h2></td>
            </tr>
            <tr
              v-if="order.type === 'Express'"
              class="tabletitle"
            >
              <td class="Rate">
                <h2>
                  <span>تكلفة الخدمة السريعة</span>
                  <br>
                  <span>Express Cost</span>
                  <br>
                </h2>
              </td>
              <td></td>
              <td></td>
              <td
                class="payment"
                colspan="2"
              >
                <h2>
                  x{{ order.express_cost }}
                </h2>
              </td>
            </tr>
            <tr
              v-if="appliedDiscount"
              class="tabletitle"
            >
              <td class="Rate">
                <h2>
                  <span>الخصم</span>
                  <br>
                  <span>Discount</span>
                  <br>
                </h2>
              </td>
              <td></td>
              <td></td>
              <td
                class="payment"
                colspan="2"
              >
                <h2>{{ appliedDiscount }} KWD</h2>
              </td>
            </tr>
            <tr class="tabletitle">
              <td class="Rate">
                <h2>
                  <span>المجموع</span>
                  <br>
                  <span>Total</span>
                  <br>
                </h2>
              </td>
              <td></td>
              <td></td>
              <td
                class="payment"
                colspan="2"
              >
                <h2>{{ (order.grand_total).toFixed(2) }} KWD</h2>
              </td>
            </tr>
            <!-- payment details -->
<!--            <tr-->
<!--              v-if="order.paid_from_wallet"-->
<!--              class="tabletitle"-->
<!--            >-->
<!--              <td class="Rate">-->
<!--                <h2>From Wallet</h2>-->
<!--              </td>-->
<!--              <td></td>-->
<!--              <td-->
<!--                class="payment"-->
<!--                colspan="2"-->
<!--              >-->
<!--                <h2>{{ (order.paid_from_wallet).toFixed(2) }} KWD</h2>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr-->
<!--              v-if="order.paid_from_upayment"-->
<!--              class="tabletitle"-->
<!--            >-->
<!--              <td class="Rate">-->
<!--                <h2>From Upayment</h2>-->
<!--              </td>-->
<!--              <td></td>-->
<!--              <td-->
<!--                class="payment"-->
<!--                colspan="2"-->
<!--              >-->
<!--                <h2>{{ (order.paid_from_upayment).toFixed(2) }} KWD</h2>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr-->
<!--              v-if="order.paid_by_cash"-->
<!--              class="tabletitle"-->
<!--            >-->
<!--              <td class="Rate">-->
<!--                <h2>From Cash</h2>-->
<!--              </td>-->
<!--              <td></td>-->
<!--              <td-->
<!--                class="payment"-->
<!--                colspan="2"-->
<!--              >-->
<!--                <h2>{{ (order.paid_by_cash).toFixed(2) }} KWD</h2>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr-->
<!--              v-if="order.paid_from_subscription"-->
<!--              class="tabletitle"-->
<!--            >-->
<!--              <td class="Rate">-->
<!--                <h2>From Subscription</h2>-->
<!--              </td>-->
<!--              <td></td>-->
<!--              <td-->
<!--                class="payment"-->
<!--                colspan="2"-->
<!--              >-->
<!--                <h2>{{ (order.paid_from_subscription).toFixed(2) }} KWD</h2>-->
<!--              </td>-->
<!--            </tr>-->
            <tr class="tabletitle">
              <td class="Rate">
                <h2>
                  <span>المستحق</span>
                  <br>
                  <span>Balance</span>
                  <br>
                </h2>
              </td>
              <td></td>
              <td></td>
              <td
                class="payment"
                colspan="2"
              >
                <h2>{{ (order.remain_after_balance_paid).toFixed(2) }} KWD</h2>
              </td>
            </tr>
            <!--            <tr-->
            <!--              v-if="order.wallet_deducted_price"-->
            <!--              class="tabletitle"-->
            <!--            >-->
            <!--              <td class="Rate">-->
            <!--                <h2>From Wallet</h2>-->
            <!--              </td>-->
            <!--              <td></td>-->
            <!--              <td-->
            <!--                class="payment"-->
            <!--                colspan="2"-->
            <!--              >-->
            <!--                <h2>{{ (order.wallet_deducted_price).toFixed(2) }} KWD</h2>-->
            <!--              </td>-->
            <!--            </tr>-->
            <!--            <tr-->
            <!--              v-if="order.subscription_deducted_price"-->
            <!--              class="tabletitle"-->
            <!--            >-->
            <!--              <td class="Rate">-->
            <!--                <h2>From Subscription</h2>-->
            <!--              </td>-->
            <!--              <td></td>-->
            <!--              <td-->
            <!--                class="payment"-->
            <!--                colspan="2"-->
            <!--              >-->
            <!--                <h2>{{ (order.subscription_deducted_price).toFixed(2) }} KWD</h2>-->
            <!--              </td>-->
            <!--            </tr>-->
            <!--            <tr class="tabletitle">-->
            <!--              <td class="Rate">-->
            <!--                <h2>Remaining Total</h2>-->
            <!--              </td>-->
            <!--              <td></td>-->
            <!--              <td-->
            <!--                class="payment"-->
            <!--                colspan="2"-->
            <!--              >-->
            <!--                <h2>{{ (order.remaining_total).toFixed(2) }} KWD</h2>-->
            <!--              </td>-->
            <!--            </tr>-->

          </table>
        </div><!--End Table-->

      </div><!--End InvoiceBot-->
      <div id="legalcopy">
        {{ footer }}
      </div>
    </div><!--End Invoice-->
  </div>
</template>
<script>
import { onMounted, ref } from '@vue/composition-api'
import axios from 'axios'
import { Printd } from 'printd'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useOrders from './useOrders'

export default {
  props: {},
  setup(props, context) {
    const toast = useToast()
    const orderId = context.root.$route.params.id
    const order = ref({})
    const appliedDiscount = ref('')
    const fullAddress = ref('')
    const footer = ref('')
    const user = ref('')
    const totalItems = ref(0)
    const {
      fetchOrder,
    } = useOrders()

    const handlePrint = () => {
      const cssText = `@media print {
          .page-break {
              display: block;
              page-break-before: always;
          }
      }

      #invoice-POS {
          box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
          padding: 2mm;
          margin: 0 auto;
          width: 44mm;
          background: #FFF;
      }

      #invoice-POS ::selection {
          background: #f31544;
          color: #FFF;
      }

      #invoice-POS ::moz-selection {
          background: #f31544;
          color: #FFF;
      }

      #invoice-POS h1 {
          font-size: 1.5em;
          color: #222;
      }

      #invoice-POS h2 {
          font-size: .5em;
      }

      #invoice-POS h3 {
          font-size: 1.2em;
          font-weight: 300;
          line-height: 2em;
      }

      #invoice-POS p {
          font-size: .5em;
          color: #666;
          line-height: 1.2em;
      }

      #invoice-POS #top, #invoice-POS #mid, #invoice-POS #mid-1, #invoice-POS #mid-2, #invoice-POS #bot {
          border-bottom: 1px solid #EEE;
      }

      #invoice-POS #top {
          min-height: 80px;
      }

      #invoice-POS #mid {
          min-height: 50px;
      }

      #invoice-POS #mid-1 {
          min-height: 30px;
      }

      #invoice-POS #bot {
          min-height: 50px;
      }

      #invoice-POS #bot h2 {
          font-size: 1.4em;
      }

      #invoice-POS #top .logo {
          height: 60px;
          width: 60px;
      }

      #invoice-POS .clientlogo {
          float: left;
          height: 60px;
          width: 60px;
          background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
          background-size: 60px 60px;
          border-radius: 50px;
      }

      #invoice-POS .info {
          display: block;
          margin-left: 0;
      }

      #invoice-POS .title {
          float: right;
      }

      #invoice-POS .title p {
          text-align: right;
      }

      #invoice-POS table {
          width: 100%;
          border-collapse: collapse;
      }

      #invoice-POS .tabletitle {
          font-size: .3em;
          margin: 3px;
          background: #EEE;
      }

      #invoice-POS .service {
          border-bottom: 1px solid #EEE;
      }

      #invoice-POS .item {
          // width: 24mm;
      }

      #invoice-POS .tableitem {
          line-height: 0.8;
      }

      #invoice-POS .itemtext {
          font-size: .4em;
      }

      #invoice-POS .noteitem {
          line-height: 1.45;
      }

      #invoice-POS .notetext {
          margin-inline: .5rem;
      }

      #invoice-POS #legalcopy {
          margin-top: 5mm;
      }`

      const d = new Printd()
      setTimeout(() => {
        d.print(document.getElementById('invoice-POS'), [cssText])
      }, 50)
    }

    onMounted(() => {
      fetchOrder(orderId).then(res => {
        order.value = res

        order.value.items.forEach(item => {
          totalItems.value += item.quantity
        })

        if (res.user_discount && res.promo_code) {
          const code = res.promo_code.amount.split(' ')
          const codeAmount = +code[0]
          const codeType = code[1]
          if (codeType === '%') {
            const tempDiscount = Math.max(codeAmount, res.user_discount)
            appliedDiscount.value = `${tempDiscount} %`
          } else {
            const tempTotal = res.total - codeAmount
            if (tempTotal === res.grand_total) {
              appliedDiscount.value = `- ${codeAmount} KWD`
            } else {
              appliedDiscount.value = `${res.user_discount} %`
            }
          }
        } else {
          if (res.user_discount) {
            appliedDiscount.value = `${res.user_discount} %`
          }

          if (res.promo_code) {
            appliedDiscount.value = res.promo_code.amount
          }
        }

        if (appliedDiscount.value.includes('%')) {
          const appliedDiscountAmount = +appliedDiscount.value.split(' ')[0]
          appliedDiscount.value = (+res.total * (appliedDiscountAmount / 100)).toFixed(2)
        }

        if (res.delivery_address) {
          const { area } = res.delivery_address
          // const city = area.city ?? null
          // if (city) fullAddress.value += ` City: ${city.name}, `
          if (area) fullAddress.value += ` Area: ${area.name}, `
          if (res.delivery_address.block) fullAddress.value += ` Block: ${res.delivery_address.block}, `
          if (res.delivery_address.avenue) fullAddress.value += ` Avenue: ${res.delivery_address.avenue}, `
          if (res.delivery_address.street) fullAddress.value += ` Street: ${res.delivery_address.street}, `
          if (res.delivery_address.building) fullAddress.value += ` Building: ${res.delivery_address.building}, `
          if (res.delivery_address.floor_number) fullAddress.value += ` Floor_number: ${res.delivery_address.floor_number}, `
          if (res.delivery_address.apartment_number) fullAddress.value += ` Apartment Number: ${res.delivery_address.apartment_number}, `
          if (res.delivery_address.landmark) fullAddress.value += ` Landmark: ${res.delivery_address.landmark}, `
          if (res.delivery_address.details) fullAddress.value += ` Details: ${res.delivery_address.details}, `
        }

        axios.get(`${process.env.VUE_APP_BASE_API_URL}/pos/users/${res.user.id}`, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }).then(userRes => {
          if (userRes.data.code === 200) {
            user.value = userRes.data.data.user

            axios.get(`${process.env.VUE_APP_BASE_API_URL}/configurations?scope=3`)
              .then(result => {
                if (result.data.code === 200) {
                  footer.value = result.data.data.configurations.pos_print_footer
                  document.getElementById('legalcopy').innerHTML = result.data.data.configurations.pos_print_footer
                  handlePrint()
                }
              })
              .catch(() => {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error fetching configurations list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching user',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      })
    })

    return {
      order,
      totalItems,
      user,
      fullAddress,
      appliedDiscount,
      footer,
      handlePrint,
    }
  },
}
</script>
<style scoped>
@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}

#invoice-POS {
  box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
  padding: 2mm;
  margin: 0 auto;
  width: 44mm;
  background: #FFF;
}

#invoice-POS ::selection {
  background: #f31544;
  color: #FFF;
}

#invoice-POS ::moz-selection {
  background: #f31544;
  color: #FFF;
}

#invoice-POS h1 {
  font-size: 1.5em;
  color: #222;
}

#invoice-POS h2 {
  font-size: .5em;
}

#invoice-POS h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}

#invoice-POS p {
  font-size: .5em;
  color: #666;
  line-height: 1.2em;
}

#invoice-POS #top, #invoice-POS #mid, #invoice-POS #mid-1, #invoice-POS #mid-2, #invoice-POS #bot {
  border-bottom: 1px solid #EEE;
}

#invoice-POS #top {
  min-height: 80px;
}

#invoice-POS #mid {
  min-height: 50px;
}

#invoice-POS #mid-1 {
  min-height: 30px;
}

#invoice-POS #bot {
  min-height: 50px;
}

#invoice-POS #bot h2 {
  font-size: 1.4em;
}

#invoice-POS #top .logo {
  height: 60px;
  width: 60px;
}

#invoice-POS .clientlogo {
  float: left;
  height: 60px;
  width: 60px;
  background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
  background-size: 60px 60px;
  border-radius: 50px;
}

#invoice-POS .info {
  display: block;
  margin-left: 0;
}

#invoice-POS .title {
  float: right;
}

#invoice-POS .title p {
  text-align: right;
}

#invoice-POS table {
  width: 100%;
  border-collapse: collapse;
}

#invoice-POS .tabletitle {
  font-size: .3em;
  margin: 3px;
  background: #EEE;
}

#invoice-POS .service {
  border-bottom: 1px solid #EEE;
}

#invoice-POS .item {
  /*width: 24mm;*/
}

#invoice-POS .tableitem {
  line-height: 0.8;
}

#invoice-POS .itemtext {
  font-size: .4em;
}

#invoice-POS .noteitem {
  line-height: 1.45;
}

#invoice-POS .notetext {
  margin-inline: .5rem;
}

#invoice-POS #legalcopy {
  font-size: 0.5em;
  margin-top: 5mm;
  color: #666;
}

#invoice-POS h2 {
  color: #666;
}

#invoice-POS span {
  color: #666;
}
</style>
